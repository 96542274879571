export const config = {
    "mapbox": {
        "key": "pk.eyJ1IjoibmVyZHlwYXJrZXIiLCJhIjoiY2p2ZDRpbTV3MXc2MTQzbjNhdjFwbmtnaSJ9.dr4_rL8E-pKTzwy_GIAyog"
    },
    "api": {
        "endpoint_domain": "https://api.cincychilimap.com"
        //"endpoint_domain": "http://localhost:4000"
    },
    "ui": {
        "title": "Cincy Chili Map - Map of Cincinnati-style chili locations"
    }
}
